import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './Components/Home';
import AdminPanel from './Components/AdminPanel';
import Men from './Components/Men';

import Women from './Components/Women';
import Gadgets from './Components/Gadgets';
import Beauty from './Components/Beauty';

import AdminPage from './Components/AdminPage';
import UserPage from './Components/UserPage';
import Acess from './Components/Acess';
import Navmat from './Components/Navmat';
import ImageUploadPage from './Components/ImageUploadPage';

import Page1 from './Components/Page1';
import Page2 from './Components/Page2';

import Page3 from './Components/Page3';
import Page4 from './Components/Page4';
import CategoryPage from './Components/CategoryPage';


const App = () => {
  return (
    <Router>
      <div>
        <Routes>
         
          <Route path="/" element={<Home/>} />
          <Route path="/men" element={<Men/>} />
          <Route path="/women" element={<Women/>} />
          <Route path="/electronics" element={<Gadgets/>} />
          <Route path="/beauty-fashion" element={<Beauty/>} />
          <Route path="/admin" element={<AdminPanel/>} />
          <Route path="/Matches-karanki matrimony" element={<UserPage/>} />
          <Route path="/listadmin" element={<Acess/>} />
          <Route path="na" element={<Navmat/>} />
          <Route path="/mainadmin" element={<AdminPage/>} />
          <Route path="/up" element={<ImageUploadPage/>} />
          <Route path="/nri-bg" element={<Page1/>} />
          <Route path="/doctor-brides" element={<Page2/>} />
          <Route path="/nri-brides" element={<Page3/>} />
          <Route path="/Doctor-bg" element={<Page4/>} />
          <Route path="/ca" element={<CategoryPage/>} />
          
        
        </Routes>
       
      </div>
    </Router>
  );
};

export default App;
