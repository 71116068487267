import React, { useState, useEffect } from "react";
import { db } from "./firebase";
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from "firebase/firestore";
import * as XLSX from "xlsx";
import { ToastContainer,toast } from "react-toastify";  // For toast notifications
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS
import './Acess.css';
import Navmat from "./Navmat";

const Acess = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [category, setCategory] = useState("");
  const [userList, setUserList] = useState([]);
  const [excelFile, setExcelFile] = useState(null);
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isEditing, setIsEditing] = useState(null);
  const [editedMobileNumber, setEditedMobileNumber] = useState("");
  const [editedCategory, setEditedCategory] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0); // Track upload progress

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, "users");
      const usersSnapshot = await getDocs(usersCollection);
      const users = usersSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setUserList(users);
    };
    fetchUsers();
  }, []);

  // Handle Excel File Upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setExcelFile(file);
  };

  // Parse Excel and Upload Numbers
  const handleBulkUpload = () => {
    if (!excelFile) {
      setError("Please select an Excel file to upload.");
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      const totalEntries = data.length;

      for (let i = 0; i < totalEntries; i++) {
        const { mobileNumber, category } = data[i]; // Expecting `category` in column B
      

        if (mobileNumber) {
          const usersCollection = collection(db, "users");
          const usersSnapshot = await getDocs(usersCollection);
          const existingUser = usersSnapshot.docs.find(
            (doc) => doc.data().mobileNumber === String(mobileNumber)
          );

          if (existingUser) {
            console.log(`Mobile number ${mobileNumber} already exists.`);
            continue;
          }

          // Add new user to the database
          await addDoc(usersCollection, { mobileNumber: String(mobileNumber), category });
        }

        // Update progress
        setUploadProgress(Math.round(((i + 1) / totalEntries) * 100));  // Calculate and update progress percentage
      }

      toast.success("Bulk upload successful!");
      setExcelFile(null);
      setUploadProgress(0);  // Reset progress after upload
    };
    reader.readAsBinaryString(excelFile);
  };

  // Add a new Mobile Number with Category
  const handleAddNumber = async () => {
    if (!mobileNumber) {
      setError("Please enter a mobile number and select a category.");
      return;
    }

    const usersCollection = collection(db, "users");
    const usersSnapshot = await getDocs(usersCollection);
    const existingUser = usersSnapshot.docs.find(
      (doc) => doc.data().mobileNumber === String(mobileNumber)
    );

    if (existingUser) {
      setError("Mobile number already exists.");
      return;
    }

    await addDoc(usersCollection, { mobileNumber: String(mobileNumber), category });

    // Add new user to the list without reloading the page
    setUserList([...userList, { mobileNumber, category }]);

    setMobileNumber("");
    setCategory("");
    setError("");
    toast.success("Number added successfully!");  // Show success toast
  };

  // Edit Mobile Number and Category
  const handleEditNumber = (user) => {
    setIsEditing(user.id);
    setEditedMobileNumber(user.mobileNumber);
    setEditedCategory(user.category);
  };

  // Save Edited Mobile Number and Category
  const handleSaveEdit = async (id) => {
    if (!editedMobileNumber || !editedCategory) {
      setError("Please enter a mobile number and select a category.");
      return;
    }
    const userRef = doc(db, "users", id);
    await updateDoc(userRef, { mobileNumber: String(editedMobileNumber), category: editedCategory });

    setUserList((prevUserList) =>
      prevUserList.map((user) =>
        user.id === id
          ? { ...user, mobileNumber: String(editedMobileNumber), category: editedCategory }
          : user
      )
    );

    setIsEditing(null);
    // Show success toast after saving
  toast.success("Details updated successfully!");

  };

  // Cancel Edit
  const handleCancelEdit = () => {
    setIsEditing(null);
  };
  

  // Delete Mobile Number
  const handleDeleteNumber = async (id) => {
    const userRef = doc(db, "users", id);
    await deleteDoc(userRef);
    setUserList((prevUserList) => prevUserList.filter((user) => user.id !== id));
  };

  // Filtered User List Based on Search Query
  const filteredUsers = userList.filter((user) =>
    String(user.mobileNumber).includes(searchQuery)
  );
  const handleInputChange = (e) => {
    const value = e.target.value;
    // Allow only numeric characters
    if (/^\d*$/.test(value)) {
      setMobileNumber(value);
    }
  };
  
  return (
    <div><Navmat/>
    <div className="forest-container">
      <h2>Admin Page</h2>

      <div className="cedar-tree">
      <input
        type="tel"  // Ensures numeric keypad on mobile devices
        value={mobileNumber}
        onChange={handleInputChange}
        maxLength={10}
        placeholder="Enter mobile number"
        inputMode="numeric" // Ensures numeric keypad on devices supporting it
      />
       <select value={category} onChange={(e) => setCategory(e.target.value)}>
  <option value="">Select Category</option>
  <option value="Doctor Brides">Doctor Brides</option>
  <option value="NRI Brides">NRI Brides</option>
  <option value="Doctor Bride-Grooms">Doctor Bride-Grooms</option>
  <option value="NRI Bride-Grooms">NRI Bride-Grooms</option>
</select>

        <button onClick={handleAddNumber}>Add Number</button>
      </div>

      <div className="maple-tree">
        <input type="file" onChange={handleFileUpload} />
        <button onClick={handleBulkUpload}>Upload Excel</button>
      </div>

      {/* Display upload progress */}
      {uploadProgress > 0 && (
        <div className="upload-progress">
          <p>Uploading: {uploadProgress}%</p>
          <div className="progress-bar">
            <div
              className="progress"
              style={{ width: `${uploadProgress}%` }}
            ></div>
          </div>
        </div>
      )}

      <div className="birch-tree">
        {error && <p>{error}</p>}
        <h3>Users List</h3>
        <div className="oak-tree">
          <input
            type="text"
            placeholder="Search by Mobile Number"
            value={searchQuery}
            maxLength={10}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Mobile Number</th>
              <th>Category</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr key={user.id}>
                <td>{index + 1}</td>
                <td>
                  {isEditing === user.id ? (
                    <input
                      type="text"
                      value={editedMobileNumber}
                      onChange={(e) => setEditedMobileNumber(e.target.value)}
                    />
                  ) : (
                    user.mobileNumber
                  )}
                </td>
                <td>
                  {isEditing === user.id ? (
                    <select
                      value={editedCategory}
                      onChange={(e) => setEditedCategory(e.target.value)}
                    ><option value="">Select Category</option>
                      <option value="Doctor Brides">Doctor Brides</option>
                      <option value="NRI Brides">NRI Brides</option>
                      <option value="Doctor Bride-Grooms">Doctor Bride-Grooms</option>
                      <option value="NRI Bride-Grooms">NRI Bride-Grooms</option>
                    </select>
                  ) : (
                    user.category
                  )}
                </td>
                <td>
                {isEditing === user.id ? (
      <>
        <button className="save" onClick={() => handleSaveEdit(user.id)}>Save</button>
        <button className="cancel" onClick={handleCancelEdit}>Cancel</button>
      </>
    ) : (
      <>
        <button className="edit" onClick={() => handleEditNumber(user)}>Edit</button>
        <button className="delete" onClick={() => handleDeleteNumber(user.id)}>Delete</button>
      </>
    )}
  </td>
</tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
    </div>
  );
};

export default Acess;
