import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import "./Navmat.css";

const Navmat = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="brand-logo">Admin-Karanki Matrimony</Link> {/* Use Link for logo */}
        <button className="hamburger" onClick={toggleMenu}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </button>
        <ul className={`nav-links ${isMenuOpen ? "open" : ""}`}>
  <li><Link to="/mainadmin" className="nav-link">Home</Link></li>
  <li className="nav-link-dropdown">
    <span>Categories</span>
    <ul className="submenu">
      <li><Link to="/nri-brides" className="nav-link">NRI Brides</Link></li>
      <li><Link to="/nri-bg" className="nav-link">NRI Bride Grooms</Link></li>
      <li><Link to="/doctor-brides" className="nav-link">Doctor Brides</Link></li>
      <li><Link to="/doctor-bg" className="nav-link">Doctor Bride Grooms</Link></li>
    </ul>
  </li>
  <li><Link to="/listadmin" className="nav-link">Add User</Link></li>
  <li><Link to="/up" className="nav-link">Upload</Link></li>
  <li><Link to="/ca" className="nav-link">Manage</Link></li>
  <li><Link to="/Matches-karanki-matrimony" className="nav-link">User</Link></li>
 
</ul>

      </div>
    </nav>
  );
};

export default Navmat;
