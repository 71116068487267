import React, { useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "./firebase"; // Adjust based on your Firebase config
import './CategoryPage.css';
import Navmat from "./Navmat";

const CategoryPage = () => {
  const categories = [
    "nri_bride_grooms",
    "doctor_brides",
    "nri_brides",
    "doctor_bride_grooms",
  ];
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      const q = query(collection(db, "profiles"), where("category", "==", selectedCategory));
      const querySnapshot = await getDocs(q);
      const fetchedImages = [];
      querySnapshot.forEach((doc) => {
        const imageUrls = doc.data().images || [];
        imageUrls.forEach((url, index) => {
          fetchedImages.push({ id: `${doc.id}-${index}`, url, docId: doc.id });
        });
      });
      setImages(fetchedImages);
    };

    fetchImages();
  }, [selectedCategory]);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  return (
    <div>
      <Navmat />
      <div className="movies-page">
        <h2 className="page-title">Manage Images</h2>

        {/* Category Selector */}
        <div className="category-selector">
          <label htmlFor="category">Select Category:</label>
          <select
            id="category"
            value={selectedCategory}
            onChange={handleCategoryChange}
            className="category-dropdown"
          >
            {categories.map((category) => (
              <option key={category} value={category}>
                {category.replace(/_/g, " ").toUpperCase()}
              </option>
            ))}
          </select>
        </div>

        {/* Display Image Count */}
        <div className="image-count">
          <h3>Total Images: {images.length}</h3>
        </div>

        {/* Image Grid */}
        <div className="movies-grid">
          {images.map((image) => (
            <div key={image.id} className="movie-item">
              <img src={image.url} alt="Gallery" className="movie-image" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryPage;
