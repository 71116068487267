import React, { useState } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { storage, db } from './firebase'; // Import Firebase configuration
import './Img.css';
import Navmat from './Navmat';

const ImageUploadPage = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [category, setCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleImageSelection = (e) => {
    setSelectedImages(e.target.files);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleUpload = async () => {
    if (!category) {
      alert('Please select a category before uploading!');
      return;
    }

    if (selectedImages.length === 0) {
      alert('Please select at least one image to upload!');
      return;
    }

    setLoading(true);
    setProgress(0);
    const imageUrls = [];

    // Upload all selected images
    const uploadPromises = Array.from(selectedImages).map((image) => {
      const imageRef = ref(storage, `images/${category}/${image.name}`);
      const uploadTask = uploadBytesResumable(imageRef, image);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress); // Update progress
          },
          (error) => {
            console.error('Error uploading image:', error);
            reject(error);
          },
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            imageUrls.push(url);
            resolve(url);
          }
        );
      });
    });

    try {
      // Wait for all images to be uploaded
      await Promise.all(uploadPromises);

      // Fetch existing category document
      const q = query(collection(db, 'profiles'), where('category', '==', category));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Update existing document
        const docRef = querySnapshot.docs[0].id;
        const profileDoc = doc(db, 'profiles', docRef);
        const existingImages = querySnapshot.docs[0].data().images || [];
        await updateDoc(profileDoc, {
          images: [...existingImages, ...imageUrls],
          timestamp: new Date(),
        });
      } else {
        // Create a new document
        await addDoc(collection(db, 'profiles'), {
          category,
          images: imageUrls,
          timestamp: new Date(),
        });
      }

      alert('Images successfully uploaded to the selected category!');
    } catch (error) {
      console.error('Error during upload:', error);
      alert('An error occurred while uploading images. Please try again.');
    } finally {
      setLoading(false);
      setProgress(0);
      setSelectedImages([]);
    }
  };

  return (
    <div>
      <Navmat />
      <div className="rose-container">
        <h2 className="orchid-header">Upload Images</h2>
        <div className="daisy-form-group">
          <label className="lily-label">Select Category:</label>
          <select value={category} onChange={handleCategoryChange} className="sunflower-select">
            <option value="">-- Select a Category --</option>
            <option value="nri_brides">NRI Brides</option>
            <option value="nri_bride_grooms">NRI Bride Grooms</option>
            <option value="doctor_brides">Doctor Brides</option>
            <option value="doctor_bride_grooms">Doctor Bride Grooms</option>
          </select>
        </div>

        <div className="tulip-form-group">
          <label className="daisy-label">Select Images:</label>
          <input
            type="file"
            multiple
            onChange={handleImageSelection}
            className="lily-input"
          />
        </div>

        {loading && (
          <div className="progress-bar-container">
            <div className="progress-bar" style={{ width: `${progress}%` }}>
              <span className="progress-text">{Math.round(progress)}%</span>
            </div>
          </div>
        )}

        <div>
          <button
            onClick={handleUpload}
            disabled={loading}
            className="button-rose"
          >
            {loading ? 'Uploading...' : 'Upload Images'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageUploadPage;
