import React, { useState, useEffect } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "./firebase";
import "./UserPage.css";

const UserPage = () => {
  const [mobileNumber, setMobileNumber] = useState(""); // For storing mobile number input
  const [pdfList, setPdfList] = useState([]); // For storing fetched PDFs
  const [filteredPdfs, setFilteredPdfs] = useState([]); // For storing filtered PDFs based on user category
  const [error, setError] = useState(""); // For storing error messages
  const [loading, setLoading] = useState(false); // For showing loading state

  useEffect(() => {
    const fetchData = async () => {
      const pdfCollection = collection(db, "pdfs");
      const data = await getDocs(pdfCollection);
      setPdfList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))); // Store PDFs
    };
    fetchData();
  }, []); // Only runs once when component mounts

  // Handle Mobile Number Change (validates 10-digit number)
const handleNumberChange = (e) => {
  const regex = /^[0-9]{10}$/; // Regex to validate 10-digit numbers
  const value = e.target.value.replace(/\D/g, ''); // Removes non-digit characters

  setMobileNumber(value);

  if (!regex.test(value)) {
    setError("Please enter a valid 10-digit mobile number.");
  } else {
    setError("");
  }
};

// Handle Enter Key Press
const handleKeyPress = (e) => {
  if (e.key === "Enter") {
    handleCheckNumber(); // Trigger the action on Enter key
  }
};

  // Check if the mobile number exists in the 'users' collection
  const handleCheckNumber = async () => {
    if (error || !mobileNumber) return; // Don't proceed if there’s an error or no number

    setLoading(true); // Start loading state
    const q = query(
      collection(db, "users"),
      where("mobileNumber", "==", mobileNumber)
    );

    try {
      const querySnapshot = await getDocs(q); // Fetch user data
      if (!querySnapshot.empty) {
        const user = querySnapshot.docs[0].data(); // Get user data from the first matching doc
        const userCategory = user.category; // Assume user document has a 'category' field

        // Filter PDFs based on user category or show all PDFs
        const filtered = userCategory === "All" 
          ? pdfList 
          : pdfList.filter((pdf) => pdf.category === userCategory);

        if (filtered.length > 0) {
          // If there are PDFs found for this category
          setFilteredPdfs(filtered); // Set the filtered PDFs to display
          setError(""); // Clear any previous errors
        } else {
          setError("No PDFs found for this user category. Please contact the admin for assistance.");
          setFilteredPdfs([]); // Clear the filtered PDFs if no match
        }
      } else {
        setError("The mobile number you entered is not registered. Please contact the admin for assistance.");
        setFilteredPdfs([]); // Clear the filtered PDFs if number not found
      }
    } catch (error) {
      setError("Error fetching data. Please try again later.");
      setFilteredPdfs([]); // Clear the filtered PDFs on error
    } finally {
      setLoading(false); // End loading state
    }
  };

  return (
    <div className="user-page-container">
          <div className="user-container">
      <h1 className="header-title">Karanki Matrimony</h1>
      <p className="header-message">Please enter your registered mobile number to access your matches.</p>


      {/* Mobile Number Input */}
      <div className="mobile-number-section">
      <input
            type="tel"
            value={mobileNumber}
            onChange={handleNumberChange}
            onKeyDown={handleKeyPress} // Add onKeyDown to listen for Enter
            placeholder="Enter 10-digit mobile number"
            maxLength={10}
            className="input-field"
          />
        <button onClick={handleCheckNumber} disabled={loading} className="action-button">
          {loading ? "Loading..." : "Search "}
        </button>
      </div>

      {error && <p className="error-message">{error}</p>} {/* Display error if any */}

      {/* PDF List */}
      {filteredPdfs.length > 0 && (
       <div className="pdf-list-container">
       <div className="pdf-folder">
         {filteredPdfs.map((pdf) => (
           <div key={pdf.id} className="pdf-folder-item">
             <div className="folder-icon"></div>
             <p className="pdf-name">{pdf.name}</p>
             <div className="password-info">
  <strong>Password: </strong>
  <span className={pdf.password ? "" : "contact-admin"}>
    {pdf.password ? pdf.password : "Contact admin"}
  </span>
</div>
<br></br>
             <a
               href={pdf.fileUrl}
               target="_blank"
               rel="noopener noreferrer"
               className="view-pdf-button"
             >
               View PDF
             </a>
            
           </div>
         ))}
       </div>
     </div>
     
      )}
    </div>
    </div>
  );
};

export default UserPage;
