import React, { useState, useEffect } from "react";
import { db, storage } from "./firebase";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import "./AdminPage.css";
import Navmat from "./Navmat";

const AdminPage = () => {
  const [pdfList, setPdfList] = useState([]);
  const [form, setForm] = useState({ name: "", category: "", file: null });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [editingPdf, setEditingPdf] = useState(null);

  // Fetch PDFs from Firebase
  useEffect(() => {
    const fetchData = async () => {
      const pdfCollection = collection(db, "pdfs");
      const data = await getDocs(pdfCollection);
      setPdfList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    fetchData();
  }, []);

  // Upload PDF to Firebase with progress
  const handleUpload = () => {
    if (form.name && form.category && form.file) {
      const fileRef = ref(storage, `pdfs/${form.file.name}`);
      const uploadTask = uploadBytesResumable(fileRef, form.file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Update progress
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress.toFixed(0));
        },
        (error) => {
          console.error("Upload failed", error);
        },
        async () => {
          // Get download URL after successful upload
          const fileUrl = await getDownloadURL(uploadTask.snapshot.ref);

          const pdfCollection = collection(db, "pdfs");
          const newDoc = await addDoc(pdfCollection, {
            name: form.name,
            category: form.category,
            fileUrl,
            password: form.password,
          });

          setPdfList((prev) => [
            ...prev,
            { id: newDoc.id, name: form.name, category: form.category, fileUrl ,password: form.password},
          ]);

          // Alert on successful upload
          alert("PDF uploaded successfully!");

          // Reset form and progress
          setForm({ name: "", category: "", file: null ,password: "" });
          setUploadProgress(0);
        }
      );
    } else {
      alert("Please fill all fields!");
    }
  };

  // Cancel edit operation
  const handleCancelEdit = () => {
    setEditingPdf(null);
  };

  // Delete PDF
  const handleDelete = async (id) => {
    const docRef = doc(db, "pdfs", id);
    await deleteDoc(docRef);
    setPdfList(pdfList.filter((item) => item.id !== id));
  };

  // Edit PDF
  const handleEdit = (item) => {
    setEditingPdf(item);
  };

  // Save edited changes
  const handleSaveEdit = async () => {
    if (editingPdf) {
      const docRef = doc(db, "pdfs", editingPdf.id);
      await updateDoc(docRef, {
        name: editingPdf.name,
        category: editingPdf.category,  // Save category change as well
        password: editingPdf.password, 
      });

      setPdfList((prev) =>
        prev.map((item) =>
          item.id === editingPdf.id
            ? { ...item, name: editingPdf.name, category: editingPdf.category,password: editingPdf.password  }
            : item
        )
      );
      setEditingPdf(null);
    }
  };

  // View PDF
  const handleViewPdf = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  return (<div><Navmat/>
    <div className="admin-page">
      <h1 className="admin-title">Admin Page</h1>
      <div className="upload-section">
        <input
          type="text"
          placeholder="Enter PDF Name"
          value={form.name}
          onChange={(e) => setForm({ ...form, name: e.target.value })}
        />
        <select
          value={form.category}
          onChange={(e) => setForm({ ...form, category: e.target.value })}
        >
          <option value="">Select Category</option>
          <option value="Doctor Brides">Doctor Brides</option>
          <option value="NRI Brides">NRI Brides</option>
          <option value="Doctor Bride-Grooms">Doctor Bride-Grooms</option>
          <option value="NRI Bride-Grooms">NRI Bride-Grooms</option>
        </select>
        <input
          type="file"
          accept="application/pdf"
          onChange={(e) => setForm({ ...form, file: e.target.files[0] })}
        />
         <input
          type="password"
          placeholder="Enter Password "
          value={form.password}
          onChange={(e) => setForm({ ...form, password: e.target.value })}
        />
        <button className="upload-btn" onClick={handleUpload}>
          Upload
        </button>
        {uploadProgress > 0 && (
          <div className="progress-bar">
            <div
              className="progress"
              style={{ width: `${uploadProgress}%` }}
            ></div>
            <span>{uploadProgress}%</span>
          </div>
        )}
      </div>

      <div className="pdf-list">
        <h2>Uploaded PDFs</h2>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Category</th>
              <th>Password</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {pdfList.map((item) => (
              <tr key={item.id}>
                <td>
                  {editingPdf && editingPdf.id === item.id ? (
                    <input
                      type="text"
                      value={editingPdf.name}
                      onChange={(e) =>
                        setEditingPdf({ ...editingPdf, name: e.target.value })
                      }
                    />
                  ) : (
                    <span>{item.name}</span>
                  )}
                </td>
                
                <td>
                  {editingPdf && editingPdf.id === item.id ? (
                    <select
                      value={editingPdf.category}
                      onChange={(e) =>
                        setEditingPdf({ ...editingPdf, category: e.target.value })
                      }
                    >
                      <option value="Doctor Brides">Doctor Brides</option>
                      <option value="NRI Brides">NRI Brides</option>
                      <option value="Doctor Bride-Grooms">Doctor Bride-Grooms</option>
                      <option value="NRI Bride-Grooms">NRI Bride-Grooms</option>
                    </select>
                  ) : (
                    <span>{item.category}</span>
                  )}
                </td>
                <td>
                  {editingPdf && editingPdf.id === item.id ? (
                    <input
                      type="password"
                      value={editingPdf.password}
                      onChange={(e) =>
                        setEditingPdf({ ...editingPdf, password: e.target.value })
                      }
                    />
                  ) : (
                    <span>******</span> // Hide password value
                  )}
                </td>
                
                <td>
                  
                  {editingPdf && editingPdf.id === item.id ? (
                    <div className="edit-mode">
                      <button onClick={handleSaveEdit} className="save-btn">
                        Save
                      </button>
                      <button onClick={handleCancelEdit} className="cancel-btn">
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div className="edit-mode">
                      <button onClick={() => handleViewPdf(item.fileUrl)} className="view-btn">
                        View
                      </button>
                      <button onClick={() => handleEdit(item)} className="edit-btn">
                        Edit
                      </button>
                      <button onClick={() => handleDelete(item.id)} className="delete-btn">
                        Delete
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  );
};

export default AdminPage;
