import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebase'; // Ensure correct Firebase configuration import
import { FaTimes, FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Icons for navigation
import './Front.css';
import logo from './log0.jpg'; // Update with the correct logo file name

const Page1 = () => {
  const [images, setImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState('');

  const correctPassword = 'NRI25'; // Predefined password (Update for production)

  // Fetch images from Firestore
  useEffect(() => {
    if (isAuthenticated) {
      const fetchImages = async () => {
        try {
          const q = query(
            collection(db, 'profiles'),
            where('category', '==', 'nri_bride_grooms')
          );
          const querySnapshot = await getDocs(q);
          const imageUrls = [];

          querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.images && Array.isArray(data.images)) {
              imageUrls.push(...data.images);
            } else {
              console.warn(`Document ${doc.id} has no valid images field`);
            }
          });

          setImages(imageUrls);
        } catch (error) {
          console.error('Error fetching images:', error);
        }
      };

      fetchImages();
    }
  }, [isAuthenticated]);

  // Disable right-click and key combinations for screenshots
  useEffect(() => {
    const handleContextMenu = (e) => e.preventDefault();
    const handleKeyDown = (e) => {
      if (
        (e.ctrlKey && ['s', 'p', 'c', 'u'].includes(e.key.toLowerCase())) || // Disable Ctrl+S, Ctrl+P, Ctrl+C, Ctrl+U
        e.key === 'PrintScreen'
      ) {
        e.preventDefault();
      }
    };

    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleCloseFullScreen = () => {
    setSelectedImageIndex(null);
  };

  const handleNext = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsAuthenticated(true);
      setError('');
    } else {
      setError('Incorrect password. Please try again.');
    }
  };

  return (
    <div className="nri-brides-page">
      {!isAuthenticated ? (
        <div className="password-protection">
          <form onSubmit={handlePasswordSubmit}>
            <img src={logo} alt="Password Protection" className="image" />
            <h3 className="dov">Enter Password to Access</h3>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              required
              className="password-input"
            />
            <button type="submit" className="submit-button">Submit</button>
          </form>
          {error && <p className="error-message">{error}</p>}
        </div>
      ) : (
        <>
          <h2 className="page-title">NRI Bride Grooms Profiles</h2>
          {images.length === 0 ? (
            <p className="no-images-message">No profiles available in this category.</p>
          ) : selectedImageIndex !== null ? (
            <div
              className="full-screen-modal"
              onClick={handleCloseFullScreen}
            >
              <button onClick={handleCloseFullScreen} className="close-button">
                <FaTimes size={30} />
              </button>
              <div className="image-viewer" onClick={(e) => e.stopPropagation()}>
                <img src={images[selectedImageIndex]} alt="Selected" className="full-image" />
              </div>
              <div className="nav-buttons" onClick={(e) => e.stopPropagation()}>
                <button onClick={handlePrevious} className="nav-button prev">
                  <FaChevronLeft size={30} />
                </button>
                <button onClick={handleNext} className="nav-button next">
                  <FaChevronRight size={30} />
                </button>
              </div>
            </div>
          ) : (
            <div className="image-grid">
              {images.map((image, index) => (
                <div
                  className="image-item"
                  key={index}
                  onClick={() => handleImageClick(index)}
                >
                  <img src={image} alt={`Profile ${index + 1}`} className="grid-image" />
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Page1;
